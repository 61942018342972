import React from 'react';

import NotFound from '~/components/NotFound';
import Seo from '~/components/seo';

export default function NotFoundPage() {
  return (
    <NotFound />
  );
}

export function Head() {
  return (
    <Seo title="페이지를 찾을 수 없습니다" />
  );
}
