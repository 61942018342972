import styled, { createGlobalStyle } from 'styled-components'
import media from '~/utils/media'

export const NotFoundGlobalStyle = createGlobalStyle`
  #___gatsby {
    min-height: 100vh;
    
    > div {
      display: flex;
      flex-direction: column;
      width: 100%;
      min-height: 100vh;
    }
  }

  .Error-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 52px;
    color: #fff;
    font-size: 15px;
    border-radius: 2px;
    background-color: #326cf9;
    cursor: pointer;
  }

  .Error-btn:hover,
  .Error-btn:active {
    background-color: #2e55d4;
  }

  @media only screen and (max-width: 320px) {
    .Error-btn {
      width: 160px;
      height: 44px;
      font-size: 14px;
    }
  }
`;


export const Body = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1600px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  -webkit-overflow-scrolling: touch;

  ${media.desk1600`
    padding-top: 80px;
  `}

  ${media.desk1200`
    padding-top: 80px;
  `}

  ${media.tabletB`
    padding-top: 100px;
  `}
  
  ${media.tablet`
    padding-top: 100px;
  `}
  
  ${media.mobile`
    max-width: 100%;
    padding-top: 50px;
  `}

  ${media.mobileS`
    max-width: 100%;
    padding-top: 50px;
  `}
`

export const ErrorContent = styled.div`
  text-align: center;
  
  ${media.desk1600`
    padding: 30px 20px 100px;
  `}

  ${media.desk1200`
    padding: 30px 20px 100px;
  `}

  ${media.tabletB`
    padding: 240px 20px 240px;
  `}
  
  ${media.tablet`
    padding: 200px 20px 200px;
  `}
  
  ${media.mobile`
    padding: 200px 20px 200px;
  `}

  ${media.mobileS`
    padding: 200px 20px 200px;
  `}
`;

export const ErrorMsg = styled.div`
  margin: 16px 0 30px;

  .Error-title {
    margin-bottom: 10px;
    color: #222;
    font-size: 22px;
    font-weight: 400;
    line-height: 27px;
  }

  .Error-desc {
    color: #222;
    font-size: 14px;
    line-height: 20px;
  }
  
  ${media.desk1600`
    margin: 16px 0 30px;
  `}

  ${media.desk1200`
    margin: 16px 0 30px;
  `}

  ${media.tabletB`
    margin: 16px 0 30px;
    
    .Error-title {
      font-size: 38px;
      line-height: 60px;
      letter-spacing: -1px;
    }

    .Error-desc {
      font-size: 20px;
      line-height: 32px;
    }
  `}
  
  ${media.tablet`
    margin: 16px 0 30px;
    
    .Error-title {
      font-size: 30px;
      line-height: 48px;
      letter-spacing: -0.5px;
    }

    .Error-desc {
      font-size: 16px;
      line-height: 26px;
    }
  `}
  
  ${media.mobile`
    margin: 16px 0 30px;
    
    .Error-title {
      font-size: 18px;
    }

    .Error-desc {
      font-size: 14px;
    }
  `}

  ${media.mobileS`
    margin: 16px 0 20px;
    
    .Error-title {
      font-size: 18px;
    }

    .Error-desc {
      font-size: 14px;
    }
  `}
`;
