import React from 'react';

import Menus from '~/components/Menus';
import Footer from '~/components/Footer';

import { GlobalStyle } from '../Layout/styled';
import {
  NotFoundGlobalStyle,
  Body,
  ErrorContent, ErrorMsg,
} from './styled';


function NotFoundComponent() {
  return (
    <>
      <GlobalStyle />
      <NotFoundGlobalStyle />
      <Menus />

      <Body>
        <ErrorContent>
          <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">
            <g fill="none" fillRule="evenodd">
              <g stroke="#222" strokeWidth="2">
                <g transform="translate(-179 -469) translate(0 149) translate(179 320)">
                  <circle cx="21" cy="21" r="20"/>
                  <path strokeLinecap="square" d="M21 29L21 29.455M21 13L21 25"/>
                </g>
              </g>
            </g>
          </svg>

          <ErrorMsg>
            <h1 className="Error-title">요청하신 페이지를 찾을 수 없습니다.</h1>
            <p className="Error-desc">주소가 바뀌었거나 잘못 입력된 주소입니다.</p>
          </ErrorMsg>

          <a href="/" className="Error-btn">홈으로 가기</a>
        </ErrorContent>
      </Body>

      <Footer />
    </>
  );
}

export default NotFoundComponent;
